<template>
  <div>
    <b-row style="border-top: solid 1px white;">
      <b-col sm="12" md="4" style="text-align: center;">
        <span style="color: white;">
            <p style="font-size: 20px;"> WisdomEra </p>
            <p>
              <router-link to="/web/about" style="text-decoration: none;">
                {{ $t('wdm16.10991') }}
              </router-link>
            </p>
            <p>
              <router-link to="/web/frequently-asked-questions" style="text-decoration: none;">
                {{ $t('wdm16.10994') }}
              </router-link>
            </p>
            <p>
              <router-link to="/web/contact" style="text-decoration: none;">
                {{ $t('wdm16.7124') }}
              </router-link>
            </p>
          </span>
      </b-col>
      <b-col sm="12" md="4" style="text-align: center;">
        <span style="color: white;">
            <p style="font-size: 20px;"> {{ $t('wdm16.11016') }} </p>
            <p><a href="https://fb.me/WisdomEra" target="_target">{{ $t('wdm16.10942') }}</a></p>
            <p><a href="https://twitter.com/WisdomEra" target="_target">{{ $t('wdm16.10943') }}</a></p>
            <p><a href="https://instagram.com/wisdomera" target="_target">{{ $t('wdm16.10945') }}</a></p>
            <p><a href="https://www.linkedin.com/company/wisdomera/" target="_target">{{ $t('wdm16.10947') }}</a></p>
          </span>
      </b-col>
      <b-col sm="12" md="4" style="text-align: center;">
        <span style="color: white;">
            <p style="font-size: 20px;"> {{ $t('wdm16.6665') }} </p>
            <p>
              <router-link to="/web/modules/polestar" style="text-decoration: none;">
                {{ $t('wdm16.6305') }}
              </router-link>
            </p>
            <p>
              <router-link to="/web/modules/article" style="text-decoration: none;">
                {{ $t('wdm16.7071') }}
              </router-link>
            </p>
            <p>
              <router-link to="/web/modules/statistics" style="text-decoration: none;">
                {{ $t('wdm16.3426') }}
              </router-link>
            </p>
            <p>
              <router-link to="/web/modules/airobots" style="text-decoration: none;">
                {{ $t('wdm16.10995') }}
              </router-link>
            </p>
          </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WebBottom',
  components: {},
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  data () {
    return {}
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>

